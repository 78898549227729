
import EditUserModal from "@/components/modals/forms/EditUserModal.vue";
import { defineComponent, ref, onMounted} from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddUserModal from "@/components/modals/forms/AddUserModal.vue";
import DeleteUserModal from  "@/components/modals/forms/DeleteUserModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";
import { ElLoading } from 'element-plus'
import router from "@/router";
import store from "@/store";

interface ICustomer {
  id: number;
  name: string;
  email: string;
  mobileNumber: string;
  roleType: string;
  createdDate: string;
  status: string;
}

export default defineComponent({
  name: "UserManagement",
  mixins: [Globals],
  components: {
    Datatable,
    AddUserModal,
    EditUserModal,
    DeleteUserModal
  },

  data() {
    return {
      group: "",
      userdata: [],
      render: false,
      useremail: "",
      username: "",
      userrole: "",
      mobile: "",
      editflag: false,
      orgId: "",
      searchUserKey: "",
      active: false,
      initCust: [] as ICustomer[],
      search: "",
      request:true,
      // visible:true,
      searchFlag:true,
      buttonActive:false,
      groupData: [] as any,
      AllData: [] as any,
      Enable:true,
      orgRole:this.getOrgRole(),
      
    };  
  },

  methods: {
    checkRole() {
      if (this.orgRole == "consumer") this.orgRole = "C";
      else if (this.orgRole == "manufacturer") this.orgRole = "M";
      else if (this.orgRole == "api_provider") this.orgRole = "P";
      else if (this.orgRole == "1SB") this.orgRole = "P";
      return this.orgRole;
    },
    
    searchItems() {
      this.userdata["data"].splice(
        0,
        this.userdata["data"].length,
        ...this.initCust
      );

      if (this.search !== "") {
        let results = [] as ICustomer[];
        for (let j = 0; j < this.userdata["data"].length; j++) {
          if (this.searchingFunc(this.userdata["data"][j], this.search)) {
            results.push(this.userdata["data"][j]);
          }

        }
        this.userdata["data"].splice(
          0,
          this.userdata["data"].length,
          ...results
        );
        if(this.userdata['data'].length==0 && this.searchFlag==true)
        {
          this.searchFlag=false
             ElNotification({
            title: 'Warning',
            message: 'No Records Found!',
            type: 'warning',
            duration: 2000,
            position: 'top-right'
          })
        }
        else if(this.userdata['data'].length!=0)
        {
          this.searchFlag=true
        }
        

        
      }
    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
     OrgDetails() {
      ApiService.get("/allOrganizationsId")
        .then((data) => {
          // loading.close()
          this.AllData = JSON.parse(JSON.stringify(data.data.data));
          console.log(this.AllData, "allData");
        })
        .catch((error) => {
          console.log(error);
          //  loading.close()
          //  if(error.response.status===401)
          //  {
          //     router.push({ name: "sign-in" });
          //  }
        });
    },
    displayData(data) {
      this.request=false
      this.userdata = JSON.parse(JSON.stringify(data));
     
      if (this.userdata.length != 0) {
        this.render = true;
      }
      this.initCust.splice(
        0,
        this.userdata["data"].length,
        ...this.userdata["data"]
      );
    },
    getuser() {
      var link = "";
      if (this.group == "") {
        link = "/user?organizationId=" + this.globalData.organizationId;
      } else {
        link = "user?organizationId=" + this.group;
      }
      this.render = false;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(link)
        .then((data) => {
          loading.close();
          this.displayData(data.data);
        })
        .catch((error) => {
          loading.close();
          ElNotification({
            title: "Warning",
            //message: "No APIs exists with '" + this.category + "' category",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
          //  if(error.response.status===401)
          //  {
          //     router.push({ name: "sign-in" });
          //  }
        });
    },

    edituser(email, mobile, name, roleType) {
    
      (this.$refs["editUserForm"] as any).callUpdate(
        email,
        mobile,
        name,
        roleType
      );
        this.buttonActive=true
    },

     enablefunction(Email,Status) {
    
      (this.$refs["deleteUserForm"] as any).callUpdate(
      
       Email,
       Status
      );
    },
    ChangeT(test) {
      this.getuser();
    },
  
    buttonReset(reset)
    {
      this.buttonActive=false
    },
    goBack(){
    this.group = ""
    this.getuser();
    if(this.getOrgRole()==='1SB'){
    this.OrgDetails();
        }
    }
   
   
  },


  mounted() {
    this.getuser();
    if(this.getOrgRole()==='1SB'){
      this.OrgDetails();
    }
  },
  
  setup() {
    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        email: "",
        enable: true
      },
    });

    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },

      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Mobile Number",
        key: "mobileNumber",
        sortable: true,
      },
      {
        name: "Role Name",
        key: "roleType",
        sortable: true,
      },
      {
        name: "Created Date",
        key: "createdDate",
        sortable: true,
      },
            {
        name: "STATUS",
        key: "status",
        sortable: true,
      },

      {
        name: "Action",
        key: "actions",
      },
      {
        name: "Delete User",
        key: "delete",
      },
    ]);


    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Users", ["Manage"]);
    });


    return {
    
      tableHeader,
      checkedCustomers,
      formData
     
    };
  },
});
