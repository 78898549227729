
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { Field, Form } from "vee-validate";
import { NAME, PHONE } from "@/helper/globalConst";
import * as Yup from "yup";
import { ElNotification } from "element-plus";
export default defineComponent({
  name: "EditUserModal",
  mixins: [Globals],
  data() {
    return {
      email: "",
      name: "",
      role: "",
      mobileno: "",
      orgRole: this.getOrgRole(),
      loading: false,
      buttonActive: true,
    };
  },
  props: {
    useremail: String,
    username: String,
    userrole: String,
    mobile: String,
  },
  components: {
    Form,
    Field,
  },

  methods: {
    buttonReset() {
      this.buttonActive=true
      this.$emit("changeButton", "false");

    },
    formReset() {
      (this.email = ""),
        (this.name = ""),
        (this.role = ""),
        (this.mobileno = "");
    },
    isUpdated()
    {
      this.buttonActive=false

    },
    callUpdate(useremail, mobile, username, userrole) {
      if (userrole == "Dev") userrole = "Developer";
      (this.email = useremail),
        (this.name = username),
        (this.role = userrole),
        (this.mobileno = mobile);
    },
    checkRole() {
      if (this.orgRole == "consumer") this.orgRole = "C";
      else if (this.orgRole == "manufacturer") this.orgRole = "M";
      else if (this.orgRole == "api_provider") this.orgRole = "P";
      else if (this.orgRole == "1SB") this.orgRole = "P";
      return this.orgRole;
    },
    updateData(formData) {
      this.loading = true;
      this.buttonActive = true;
      this.$emit("changeButton", "false");
      formData.organizationId = this.globalData.organizationId;
      formData.organizationType = this.checkRole();
      formData.data.email = this.email;
      formData.data.roleType = this.role;
      formData.data.name = this.name;
      formData.data.mobileNumber = this.mobileno;
      formData.data.orgRole = this.getOrgRole();
      formData.data.organizationId = this.globalData.organizationId;
      ApiService.put("/user", formData)
        .then((data) => {
          this.loading = false;
          
          hideModal(this.editCustomerModalRef);
          this.$emit("changeTitle", "false");
          ElNotification({
            title: "Success",
            message: "User Updated!",
            type: "success",
            duration: 2000,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.buttonActive = false;
          ElNotification({
            title: "Error",
            message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
            type: "error",
            duration: 2000,
            position: "top-right",
          });
        });
    },
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const editCustomerModalRef = ref<null | HTMLElement>(null);
    // const loading = ref<boolean>(false);
    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        orgRole: "",
        organizationId: "",
        name: "",
        email: "",
        mobileNumber: "",
        roleType: "",
      },
    });

    const formValidation = Yup.object().shape({
      name: Yup.string()
        .max(45, "Name can be atmost 45 characters")
        .matches(NAME, "Name should be only characters")
        .required()
        .label("Name"),

      role: Yup.string().required().label("Role"),

      mobileNumber: Yup.string()
        .min(10, "Mobile number should be exactly 10 digits")
        .max(10, "Mobile number should be exactly 10 digits")
        .matches(PHONE, "Mobile number must be only digits")
        .required()
        .label("Mobile Number"),
    });

    return {
      formData,
      formValidation,
      formRef,
      editCustomerModalRef,
    };
  },
});
